<script>
export default {
  props: ['id'],
  template: '#supplier-map-template',
  data: function() {
    return {
      item: {},
      catMatToSearch: '',
      currentTab: 'suppliers',
      loading: false,
      error: null,
      filters: {
        byName: '',
        minPrice: 0,
        maxPrice: 0,
        byState: 'Todos',
        byCity: 'Todos',
        byBrand: 'Todos',
        byOrgan: 'Todos',
        bySupplyUnit: 'Todos'
      },
      currentPage: 1,
      itemsPerPage: 30
    };
  },
  computed: {
    medianPricesByState: function() {
      if (this.item.suppliers === undefined) {
        return {};
      }

      let pricesByState = this.filteredItems.reduce((acc, supplier) => {
        if (acc[supplier.estado] === undefined) {
          acc[supplier.estado] = [];
        }
        acc[supplier.estado].push(supplier.precoUnitario);
        return acc;
      }, {});

      let medianPrices = {};
      Object.keys(pricesByState).forEach(state => {
        let prices = pricesByState[state];
        prices.sort((a, b) => a - b);
        let median = 0;
        if (prices.length % 2 === 0) {
          median = (prices[prices.length / 2 - 1] + prices[prices.length / 2]) / 2;
        } else {
          median = prices[(prices.length - 1) / 2];
        }
        medianPrices[state] = median;
      });

      // sort by median price
      let sortedStates = Object.keys(medianPrices).sort((a, b) => medianPrices[a] - medianPrices[b]);
      let sortedMedianPrices = {};
      sortedStates.forEach(state => {
        sortedMedianPrices[state] = medianPrices[state];
      });
      return sortedMedianPrices;
    },
    medianPrice: function() {
      if (this.item.suppliers === undefined) {
        return 0;
      }

      let prices = this.filteredItems.map(supplier => supplier.precoUnitario);
      prices.sort((a, b) => a - b);
      let median = 0;
      if (prices.length % 2 === 0) {
        median = (prices[prices.length / 2 - 1] + prices[prices.length / 2]) / 2;
      } else {
        median = prices[(prices.length - 1) / 2];
      }

      return median;
    },
    averagePrice: function() {
      if (this.item.suppliers === undefined) {
        return 0;
      }

      let prices = this.filteredItems.map(supplier => supplier.precoUnitario);
      let sum = prices.reduce((acc, price) => acc + price, 0);
      return sum / prices.length;
    },
    minPrice: function() {
      if (this.item.suppliers === undefined) {
        return 0;
      }

      let prices = this.filteredItems.map(supplier => supplier.precoUnitario);
      return Math.min(...prices);
    },
    maxPrice: function() {
      if (this.item.suppliers === undefined) {
        return 0;
      }

      let prices = this.filteredItems.map(supplier => supplier.precoUnitario);
      return Math.max(...prices);
    },
    filteredItems: function() {
      if (this.item.suppliers === undefined) {
        return [];
      }

      return this.item.suppliers.filter(item => {
        return (
          (this.filters.byName === '' || item.name.toLowerCase().includes(this.filters.byName.toLowerCase())) &&
          (this.filters.minPrice === 0 || item.precoUnitario >= this.filters.minPrice) &&
          (this.filters.maxPrice === 0 || item.precoUnitario <= this.filters.maxPrice) &&
          (this.filters.byState === 'Todos' || item.estado.toLowerCase() == this.filters.byState.toLowerCase()) &&
          (this.filters.byBrand === 'Todos' || (item.marca && item.marca.toLowerCase() == this.filters.byBrand.toLowerCase())) &&
          (this.filters.byOrgan === 'Todos' || item.nomeOrgao.toLowerCase() == this.filters.byOrgan.toLowerCase()) &&
          (this.filters.bySupplyUnit === 'Todos' || (item.siglaUnidadeFornecimento && item.siglaUnidadeFornecimento.toLowerCase() == this.filters.bySupplyUnit.toLowerCase())) &&
          (this.filters.byCity === 'Todos' || (this.cityAndStateCombined(item).toLowerCase()).includes(this.filters.byCity.toLowerCase()))
        );
      })
      .sort((a, b) => a.precoUnitario - b.precoUnitario);
    },
    paginatedItems: function() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredItems.slice(start, end);
    },
    totalPages: function() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    },
    allStates: function() {
      if (this.item.suppliers === undefined) {
        return [];
      }

      let states = this.item.suppliers
        .map(supplier => supplier.estado.toUpperCase())
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort()

      states.unshift('Todos');
      return states;
    },
    allBrands: function() {
      if (this.item.suppliers === undefined) {
        return [];
      }

      let brands = this.item.suppliers
      .map(supplier => supplier.marca ? supplier.marca.toUpperCase() : '-- N/A --')
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort();

      brands.unshift('Todos');
      return brands;
    },
    allOrgans: function() {
      if (this.item.suppliers === undefined) {
        return [];
      }

      let organs = this.item.suppliers
        .map(supplier => supplier.nomeOrgao.toUpperCase())
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort();

      organs.unshift('Todos');
      return organs;
    },
    allSupplyUnits: function() {
      if (this.item.suppliers === undefined) {
        return [];
      }

      let supplyUnits = this.item.suppliers
        .map(supplier => supplier.siglaUnidadeFornecimento ? supplier.siglaUnidadeFornecimento.toUpperCase() : '-- N/A --')
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort();

      supplyUnits.unshift('Todos');
      return supplyUnits;
    },
    allCities: function() {
      if (this.item.suppliers === undefined) {
        return [];
      }

      let cities = this.item.suppliers
        .map(supplier => 
          this.cityAndStateCombined(supplier).toUpperCase()
        )
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort();

      cities.unshift('Todos');
      return cities;
    }
  },
  methods: {
    formatCNPJorCPF: function(cnpjOrCpf) {
      if (cnpjOrCpf.length === 11) {
        return cnpjOrCpf.replace(
          /(\d{3})(\d{3})(\d{3})(\d{2})/,
          '$1.$2.$3-$4'
        );
      } else {
        return cnpjOrCpf.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          '$1.$23.$4/$5'
        );
      }
    },
    formatDate: function(date) {
      if (date === undefined || date === null) {
        return '--';
      }
      return new Date(date).toLocaleDateString();
    },
    brl: function(value) {
      if (value === undefined || value === null) {
        return '--';
      }
      return 'R$ ' + value.toFixed(2).replace('.', ',');
    },
    submitCatmatCode: function() {
      window.location.href = '/mapa-fornecedores?code=' + this.catMatToSearch;
    },
    loadData: function() {
      var self = this;
      this.loading = true;
      this.error = null;
      // get the code param from the URL query string
      var codeQueryParam = new URLSearchParams(window.location.search).get('code');
      this.catMatToSearch = codeQueryParam;

      $.ajax({
        url: '/mapa-fornecedores.json?code=' + codeQueryParam,
        success: function(data) {
          self.item = data;
          self.loading = false;
        },
        error: function(xhr, status, error) {
          self.error = 'Failed to load data: ' + error;
          self.loading = false;
        }
      });
    },
    clearFilters: function() {
      this.filters = {
        byName: '',
        minPrice: 0,
        maxPrice: 0,
        byState: 'Todos',
        byCity: 'Todos',
        byBrand: 'Todos',
        byOrgan: 'Todos',
        bySupplyUnit: 'Todos'
      };
    },
    cityAndStateCombined: function(item) {
      if (item === undefined) {
        return '';
      }

      if (item.estado === undefined || item.municipio === undefined) {
        return '';
      }

      return item.estado + ' - ' + item.municipio;
    },
    hasAnyFilters: function() {
      if (this.item.suppliers === undefined) {
        return false;
      }
      return this.filteredItems.length !== this.item.suppliers.length;
    },
    nextPage: function() {
      if (this.currentPage * this.itemsPerPage < this.filteredItems.length) {
        this.currentPage++;
        this.scrollToTop();
      }
    },
    previousPage: function() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.scrollToTop();
      }
    },
    goToPage: function(page) {
      this.currentPage = page;
      this.scrollToTop();
    },
    scrollToTop: function() {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    }
  },
  created: function() {
    this.loadData();
  },
  watch: {
    filters: {
      handler: function() {
        this.currentPage = 1;
      },
      deep: true
    }
  }
};
</script>