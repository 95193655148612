import { createApp } from 'vue'
import { createBootstrap } from 'bootstrap-vue-next'

import VueChartkick from 'vue-chartkick'
import 'chartkick/chart.js'

import SearchForm from '../components/SearchForm.vue'
import ProductSearch from '../components/ProductSearch.vue'
import ProductSearchItem from '../components/ProductSearchItem.vue'
import QuotationSuggestions from '../components/QuotationSuggestions.vue'
import FullScreenOverlay from '../components/FullScreenOverlay.vue'
import ChatRoom from '../components/ChatRoom.vue'
import EmptyStateSuggestion from '../components/EmptyStateSuggestion.vue'
import SupplierMap from '../components/SupplierMap.vue'

import { BButton, BCard, BFormInput } from 'bootstrap-vue-next';

document.addEventListener('DOMContentLoaded', () => {
  const app = createApp({});

  const aliases = {
    'b-btn': BButton,
    'b-cardcomponent': BCard,
    'b-input': BFormInput,
  };  

  const bootstrapVueOptions = {
    components: {
      BButton,
      BCard,
      BFormInput,
    },
    aliases
  };

  app.use(createBootstrap(bootstrapVueOptions));
  app.component('EmptyStateSuggestion', EmptyStateSuggestion);
  app.component('SearchForm', SearchForm);
  app.component('ProductSearch', ProductSearch);
  app.component('ProductSearchItem', ProductSearchItem);
  app.component('QuotationSuggestions', QuotationSuggestions);
  app.component('FullScreenOverlay', FullScreenOverlay);
  app.component('ChatRoom', ChatRoom);
  app.component('SupplierMap', SupplierMap);
  app.use(VueChartkick);
  app.mount('#app');
})