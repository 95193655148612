<script>
export default {
  name: 'ProductSearch',
  template: '#product-search-template',
  data() {
    return {
      isLoading: true,
      isAddingToReportIds: [],
      isAddedToReportIds: [], 
      expandedIds: [],
      search: {},
      flatResults: [],
      percentage: 0,
      report: {},
      quotation: {},
      filters: {},
      chat: {},
      filteredGroups: {},
      emptyFilters: {
        sortBy: 'default',
        states: [],
        cities: [],
        sources: [],
        units: [],
        terms: [],
        laws: [],
        dateScopes: [],
        precisions: [],
        groups: [],
        priceRange: {
          min: 0.0,
          max: 0.0
        },
        priceTypes: [],
        documentTitles: [],
        buyers: [],
        suppliers: []
      },
    };
  },
  methods: {
    cleanFilters() {
      return {
        states: [],
        cities: [],
        sources: [],
        units: [],
        terms: [],
        laws: [],
        dateScopes: [],
        precisions: [],
        groups: [],
        sortBy: 'default',
        priceRange: {
          min: 0.0,
          max: 0.0
        },
        priceTypes: [],
        documentTitles: [],
        filteredGroups: {},
        buyers: [],
        suppliers: []
      };
    },
    formatToBRL(price) {
      return window.priceHelpers.brl(price);
    },
    truncate(text, length) {
      return text.length > length ? text.substring(0, length) + "..." : text;
    },
    median(group) {
      return window.priceHelpers.medianPrice(group);
    },
    average(group) {
      return window.priceHelpers.averagePrice(group);
    },
    isOnReport(item) {
      return this.isAddedToReportIds.includes(item.id);
    },
    clearFilters() {
      this.filters = this.cleanFilters();
    },
    isExpanded(id) {
      return this.expandedIds.includes(id);
    },
    prettyJSON(json) {
      return JSON.parse(json);
    },
    toggleExpand(id) {
      if (this.expandedIds.includes(id)) {
        this.expandedIds = this.expandedIds.filter(item => item !== id);
      } else {
        this.expandedIds.push(id);
      }
    },
    toggleFilter(value, filter) {
      const index = filter.indexOf(value);
      if (index > -1) {
        filter.splice(index, 1);
      } else {
        filter.push(value);
      }
    },
    getButtonClass(selectedArray, value) {
      return selectedArray.includes(value) ? 'btn-primary' : 'btn-outline-primary';
    },
    isAddingToReport(item) {
      return this.isAddingToReportIds.includes(item.id);
    },
    async addToReport(item) {
      try {
        this.isAddingToReportIds.push(item.id);

        const url = `/product_results/${item.id}/add_to_report`;
        const body = {
          report_id: this.report.id,
          result_id: item.id
        };

        const response = await axios.post(url, body);

        console.log(response.data);

        if (response.status === 200) {
          this.isAddedToReportIds.push(item.id);
          this.isAddingToReportIds = this.isAddingToReportIds.filter(id => id !== item.id);
        } else {}
      } catch (error) {
        this.isAddingToReportIds = this.isAddingToReportIds.filter(id => id !== item.id);
        console.error('Houve um erro ao fazer a requisição:', error);
      }
    },
    getFilterAllResults() {
      return window.location.search.split('filter=')[1] || '';
    },
    async reload() {
      try {
        let searchId = $("#app-product-searches").data("id");
        const url = `/product_searches/${searchId}.json?filter=${this.getFilterAllResults()}`;

        const response = await axios.get(url);

        if (response.status === 200) {
          this.flatResults = response.data.flat_results;
          this.search = response.data.search;
          this.isLoading = false;
          this.report = response.data.report;
          this.quotation = response.data.quotation;
          this.chat = response.data.chat;
          this.isAddedToReportIds.push(...response.data.report.result_ids);
          this.isAddedToReportIds = [...new Set(this.isAddedToReportIds)];

          if (this.search.percentage !== 100) {
            setTimeout(() => {
              this.reload();
            }, 1000);
          }

        } else {
          setTimeout(() => {
            this.reload();
          }, 1000);
        }
      } catch (error) {
        console.error('Houve um erro ao fazer a requisição:', error);
      }
    },
    validateMinPrice(event) {
      this.filters.priceRange.min = Number(this.filters.priceRange.min);
    },
    validateMaxPrice(event) {
      this.filters.priceRange.max = Number(this.filters.priceRange.max);
    },
    itemLink(item) {
      return `/product_results/${item.id}`;
    },
  },
  computed: {
    allPriceClusters() {
      let results = [...this.flatResults];
      let prices = results.map(result => result.price).filter(price => price !== undefined && price !== null);

      if (prices.length === 0) {
        return [];
      }

      prices = prices.sort((a, b) => a - b);

      let clusters = [];
      let cluster = [];
      cluster.push(prices.shift());

      while (prices.length > 0) {
        let price = prices.shift();
        if (price < cluster[0] * 1.25) {
          cluster.push(price);
        } else {
          clusters.push(cluster);
          cluster = [price];
        }
      }
      clusters.push(cluster);

      let response = clusters.map(cluster => ({
        min: Math.min(...cluster),
        max: Math.max(...cluster)
      }));

      return response;
    },
    localStorageKey() {
      let searchId = $("#app-product-searches").data("id");
      return "app-filter-" + searchId;
    },
    tabs() {
      return this.flatResults.map(item => item.group).filter((value, index, self) => self.indexOf(value) === index && value !== null).sort();
    },
    filterGroups() {
      return [
        { title: 'Tipo de Preço', items: this.allPriceTypes, visible: true, selected: this.filters.priceTypes },
        { title: 'Categorias', items: this.allGroups, visible: true, selected: this.filters.groups },
        { title: 'Estados', items: this.allStates, visible: true, selected: this.filters.states },
        { title: 'Cidades', items: this.allCities, visible: this.filters.states.length > 0, selected: this.filters.cities },
        { title: 'Unidades', items: this.allUnits, visible: true, selected: this.filters.units },
        { title: 'Período', items: this.allDateScopes, visible: true, selected: this.filters.dateScopes },
        { title: 'Fontes', items: this.allSources, visible: true, selected: this.filters.sources },
        { title: 'Termos relacionados', items: this.allTerms, visible: true, selected: this.filters.terms },
        { title: 'Documento', items: this.allDocumentTitles, visible: true, selected: this.filters.documentTitles },
        { title: 'Lei 14.133/2021, Art. 23, § 1º', items: this.allLaws, visible: true, selected: this.filters.laws },
        { title: 'Órgãos', items: this.allBuyers, visible: true, selected: this.filters.buyers },
        { title: 'Fornedores', items: this.allSuppliers, visible: true, selected: this.filters.suppliers }
      ];
    },
    isEmpty() {
      return Object.keys(this.flatResults).length === 0 && this.flatResults.constructor === Object;
    },
    reportLink() {
      return `${this.search.get_report_url}`;
    },
    totalResultsCount() {
      return this.flatResults.length;
    },
    filteredResults() {
      var results = this.flatResults.filter(item =>
        (this.filters.states.length === 0 || this.filters.states.includes(item.state)) &&
        (this.filters.cities.length === 0 || this.filters.cities.includes(item.city)) &&
        (this.filters.units.length === 0 || this.filters.units.includes(item.normalized_unit)) &&
        (this.filters.terms.length === 0 || this.filters.terms.includes(item.term)) &&
        (this.filters.laws.length === 0 || this.filters.laws.includes(item.legal_support)) &&
        (this.filters.priceTypes.length === 0 || this.filters.priceTypes.includes(item.price_type)) &&
        (this.filters.dateScopes.length === 0 || this.filters.dateScopes.includes(item.date_scope)) &&
        (this.filters.precisions.length === 0 || this.filters.precisions.includes(item.term_precision)) &&
        (this.filters.groups.length === 0 || this.filters.groups.includes(item.group)) &&
        (this.filters.priceRange.min === 0 || item.price >= this.filters.priceRange.min) &&
        (this.filters.priceRange.max === 0 || item.price <= this.filters.priceRange.max) &&
        (this.filters.documentTitles.length === 0 || this.filters.documentTitles.includes(item.document_title)) &&
        (this.filters.buyers.length === 0 || this.filters.buyers.includes(item.buyer)) &&
        (this.filters.suppliers.length === 0 || this.filters.suppliers.includes(item.supplier)) &&
        (this.filters.sources.length === 0 || this.filters.sources.includes(item.source_name))
      );

      if (this.filters.sortBy === 'price_desc') {
        return results.sort((a, b) => (b.price || 0) - (a.price || 0));
      }
      
      if (this.filters.sortBy === 'price_asc') {
        return results.sort((a, b) => (a.price || 0) - (b.price || 0));
      }

      return results;
    },
    allStates() {
      var states = this.flatResults.map(item => item.state);
      states = states.filter(state => state !== '');
      states = states.filter(state => state !== null);
      states = [...new Set(states)];
      states = states.sort();
      return states;
    },
    allUnits() {
      return [...new Set(this.flatResults.map(item => item.normalized_unit).filter(normalized_unit => normalized_unit !== ''))].sort();
    },
    allSources() {
      return [...new Set(this.flatResults.map(item => item.source_name))].sort();
    },
    allPrecisions() {
      return [...new Set(
        this.flatResults.map(item => item.term_precision).filter(term_precision => term_precision !== '').filter(term_precision => term_precision !== null)
      )].sort();
    },
    allLaws() {
      return [...new Set(this.flatResults.map(item => item.legal_support))].sort();
    },
    allDateScopes() {
      return [...new Set(this.flatResults.map(item => item.date_scope))].sort();
    },
    allTerms() {
      // remove accents
      let terms = this.flatResults.map(item => item.term);
      terms = terms.map(term => term.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
      // remove duplicates
      terms = [...new Set(terms)];
      // sort
      terms = terms.sort();
      return terms;
    },
    allTags() {
      return [...new Set(this.search.tags)].sort();
    },
    allPriceTypes() {
      let terms = this.flatResults.map(item => item.price_type);
      terms = [...new Set(terms)];
      terms = terms.sort();
      return terms
    },
    allCities() {
      let cities = [...new Set(this.flatResults.map(item => item.city))]
      let activeStates = this.filters.states;
      if (activeStates.length > 0) {
        cities = cities.filter(city => activeStates.includes(this.flatResults.find(item => item.city === city).state));
      }
      cities = cities.filter(city => city !== '');
      return cities.sort();
    },
    allGroups() {
      var values = [...new Set(this.flatResults.map(item => item.group))].sort();
      if (this.search.term.toUpperCase()) {
        values = values.sort((a, b) => {
          if (a === null) {
            return 1;
          } else if (b === null) {
            return -1;
          }
          if (a.toUpperCase().includes(this.search.term.toUpperCase())) {
            return -1;
          } else if (b.toUpperCase().includes(this.search.term.toUpperCase())) {
            return 1;
          } else {
            return 0;
          }
        });
      }
      return values.filter(value => value !== null && value !== '');
    },
    allDocumentTitles() {
      let values = [...new Set(this.flatResults.map(item => item.document_title))].sort();
      return values.filter(value => value !== null && value !== '');
    },
    allBuyers() {
      let values = [...new Set(this.flatResults.map(item => item.buyer))].sort();
      return values.filter(value => value !== null && value !== '');
    },
    allSuppliers() {
      let values = [...new Set(this.flatResults.map(item => item.supplier))].sort();
      return values.filter(value => value !== null && value !== '');
    },
    currentGroup() {
      // if empty
      if (this.filters.groups === undefined || this.filters.groups.length === 0) {
        return "Categoria";
      }
      return this.filters.groups[0];
    },
  },
  watch: {
    filters: {
      handler: function(newValue, oldValue) {
        this.filters = newValue;
        localStorage.setItem(this.localStorageKey, JSON.stringify(newValue));
      },
      deep: true
    }
  },
  mounted() {
    const previousFilter = JSON.parse(localStorage.getItem(this.localStorageKey));
    if (previousFilter) {
      this.filters = { ...this.emptyFilters, ...previousFilter };
    } else {
      this.filters = this.cleanFilters();
    }
    this.reload();
  }
}
</script>